<template>
  <div class="reg">
    <div class="h40"></div>
    <div class="column back ml25 "><img src="~images/fanhui.png" alt="" @click="onBack"></div>
    <div class="column just-center padding">
        <div class="column mt60">
            <span class="font60 f-1A1A1A">注册</span>
            <span class="font28 f-666666 mt40">欢迎使用“爱购”平台</span>
        </div>
        <div class="row align-center mt143">
            <div class="back"><img src="~images/saoma.png" alt=""></div>
            <input class="font28 f-999999 ml25 flex1 input" type="text" v-model="invitation_code" placeholder="请输入正确的邀请码">
            <div class="back ml16"><img src="~images/xiala.png" alt=""></div>
        </div>
        <div class="line mt40" style="background-color: #F62341;"></div>
        <div class="row align-center mt60">
            <div class="back"><img src="~images/sj.png" alt=""></div>
            <input class="font28 f-999999 ml25 flex1 input" v-model="mobile" type="text" placeholder="请输入手机号">
        </div>
        <div class="line mt40" style="background-color: #F62341;"></div>

        <div class="row align-center mt60">
            <div class="back"><img src="~images/yzm.png" alt=""></div>
            <input class="font28 f-999999 ml25 flex1 input" type="text" v-model="sms_code" placeholder="请输入验证码">
            <div v-show="sendAuthCode" class="ml16 font28 f-2295F6" @click="getAuthCode">获取验证码</div>
            <div v-show="!sendAuthCode" class="ml16 font28 f-999999"><span class="auth_text_blue">{{auth_time}} </span> 秒后重新发送验证码</div>
        </div>
        <div class="line mt40" style="background-color: #F62341;"></div>

        <div class="row align-center mt60">
            <div class="back"><img src="~images/mima.png" alt=""></div>
            <input class="font28 f-999999 ml25 flex1 input" v-model="password" type="password" placeholder="请输入密码（至少6位）">
            <div class="ml16 bubiao"><img src="~images/biyan.png" alt="">
            </div>
        </div>
        <div class="line mt40" style="background-color: #F62341;"></div>

        <div class="row flex-center mt60" @click="onReg"><span class="btn-red">注册</span></div>
    </div>
    <div class="blank"></div>
  </div>
</template>

<script>
// @ is an alias to /src
import {reg,sms_codes} from 'network/login'
export default {
  name: 'Login',
  data() {
    return {
      sendAuthCode:true,/*布尔值，通过v-show控制显示‘获取按钮’还是‘倒计时’ */
      auth_time: 0, /*倒计时 计数器*/
      invitation_code: '',//邀请码
      mobile: '',//手机号
      sms_code: '',//验证码
      password: ''//密码
    }
  },
  methods: {
    onBack() {
      this.$router.back();
    },
    /**
     * 获取短信验证码
     */
    getAuthCode() {
      if(!this.mobile) {
        this.$toast.show('请输入手机号');
        return;
      }
      sms_codes(this.mobile).then(res=>{
        //console.log(res)
        //console.log('发送成功')
        this.$toast.show('发送成功');
        this.sendAuthCode = false;
        this.auth_time = 60;
        var auth_timetimer =  setInterval(()=>{
            this.auth_time--;
            if(this.auth_time<=0){
                this.sendAuthCode = true;
                clearInterval(auth_timetimer);
            }
        }, 1000);
      },err=>{
        console.log(err)
      })
    },
    
    onReg() {
      if(!this.mobile) {
        this.$toast.show('手机号不能为空');
        return;
      }
      if(!this.sms_code) {
        this.$toast.show('短信验证码不能为空');
        return;
      }
      if(!this.password) {
        this.$toast.show('密码不能为空');
        return;
      }
      if(!this.invitation_code) {
        this.$toast.show('邀请码不能为空');
        return;
      }
      let params = {
        mobile: this.mobile,
        sms_code: this.sms_code,
        password: this.password,
        password_confirmation: this.password,
        invitation_code: this.invitation_code
      }
      reg(params).then(res=>{
        if(res){
          this.onBack();
        }
      })
    }
  }
}
</script>
<style  scoped>
.reg{
  background-color: #fff;
  height: 100vh;
}
.padding{
    padding: 0 .866667rem;
}
.back img{
    width: .426667rem;
    height: .426667rem;
}
.underline{
    width:1.066667rem;
    height:.026667rem;
    background-color:rgba(246,35,65,1);
}
.input{
    border: none;
    outline: none;
    cursor: pointer;
}
.bubiao img{
    width: .426667rem;
    height: .426667rem;
}


</style>
